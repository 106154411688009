import {Component} from '@angular/core';
import {Store} from '@ngrx/store';
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {environment} from '../../../../../environments/environment'
import * as AuthActions from '../../store/actions/auth.action';
import {AuthState} from '../../store/reducers/auth.reducer';
import {RouterLink} from "@angular/router";
import {InputTextModule} from "primeng/inputtext";
import {ButtonDirective} from "primeng/button";
import {NgIf} from "@angular/common";
import {selectUserInformation} from "../../store/selectors/auth.selector";

@Component({
  selector: 'app-login',
  standalone: true,
  templateUrl: './login.component.html',
  imports: [
    ReactiveFormsModule,
    RouterLink,
    InputTextModule,
    ButtonDirective,
    NgIf
  ],
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  loginForm: FormGroup;
  showLoginForm: boolean = true
  protected readonly environment = environment;

  constructor(private fb: FormBuilder, private store: Store<AuthState>) {
    this.loginForm = this.fb.group({
      username: new FormControl('', Validators.required),
      password: new FormControl('')
    })

    this.store.select(selectUserInformation).subscribe(dataState => {
      if (dataState && dataState.id) {
        this.showLoginForm = false;
      } else {
        this.showLoginForm = true;
      }

    })
  }

  onLogin() {
    if (this.loginForm.status == "VALID") {
      this.store.dispatch(AuthActions.loginStart({
        username: this.loginForm.value.username,
        password: this.loginForm.value.password
      }));

    }
  }
}
