<div class="inside-main-container">
  <div class="p-fluid center-div">

    <div class="field grid">

      <div class="col-3">

        <label class="col-fixed">Name</label>
        <app-inline-edit
          [value]="user.name"
          name="name"
          [dataType]="'text'"
          (valueChange)="valueChange($event)"
        >
        </app-inline-edit>
      </div>
      <div class="col-3">
        <label class="col-fixed">User Name</label>
        <app-inline-edit
          [value]="user.username"
          name="username"
          [dataType]="'text'"
          (valueChange)="valueChange($event)"
        >
        </app-inline-edit>
      </div>
    </div>

    <div class="field grid">

      <div class="col-3">

        <label class="col-fixed">Password</label>
        <app-inline-edit
          [value]=""
          name="password"
          [dataType]="'text'"
          (valueChange)="valueChange($event)"
        >
        </app-inline-edit>
      </div>
      <div class="col-3">
        <label class="col-fixed">Email Address</label>
        <app-inline-edit
          [value]="user.email"
          name="email"
          [dataType]="'text'"
          (valueChange)="valueChange($event)"
        >
        </app-inline-edit>
      </div>
    </div>

    <div class="field grid">

      <div class="col-3">
        <img src='{{user.profilePic}}' alt="Profile Pic">
        Your Photo
        <p-fileUpload
          name="file"
          chooseLabel="Choose"
          chooseIcon="pi pi-upload"
          accept="image/*"
          maxFileSize="1000000"
          url="http://localhost:3004/api/auth/upload"
          (onUpload)="onUpload($event)"/>
      </div>
    </div>


  </div>
</div>
