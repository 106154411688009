import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from "../../../../../environments/environment";
import {UserDto} from "../models/User.model";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private token: string = "";
  apiUrl = `${environment.myBackend}/auth`;

  constructor(private http: HttpClient) {
  }

  login(username: string, password: string): Observable<{
    ssid: string,
    userKey: string,
    token: string,
    user: UserDto
  }> {
    return this.http.post<{
      ssid: string,
      userKey: string,
      token: string,
      user: UserDto
    }>(`${this.apiUrl}/login`, {username, password});
  }

  authentication(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/isAuthenticated`);
  }

  register(username: string, password: string): Observable<{ ssid: string, userKey: string, }> {
    return this.http.post<{ ssid: string, userKey: string, }>(`${this.apiUrl}/register`, {username, password});
  }

  updateMyInfo(values: updateMyInfoReq): Observable<UserDto> {
    return this.http.patch<UserDto>(`${this.apiUrl}/update`, values);
  }
  uploadImage(values: uploadReq): Observable<UserDto> {
    return this.http.post<UserDto>(`${this.apiUrl}/upload`, values);
  }
}

export interface updateMyInfoReq {
  name?: string,
  username?: string,
  password?: string,
  email?: string
}

export interface uploadReq {
  image: File,
}
