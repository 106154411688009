import {Component} from '@angular/core';
import {Store} from '@ngrx/store';
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import * as AuthActions from '../../store/actions/auth.action';
import {AuthState} from '../../store/reducers/auth.reducer';

@Component({
  selector: 'app-register',
  standalone: true,
  templateUrl: './register.component.html',
  imports: [
    ReactiveFormsModule
  ],
  styleUrls: ['./register.component.css']
})
export class RegisterComponent {
  registerForm: FormGroup;

  constructor(private fb: FormBuilder,private store: Store<AuthState>) {
    this.registerForm = this.fb.group({
      username: new FormControl('',Validators.required),
      password: new FormControl('',Validators.required)
    })
  }

  onRegister() {
    if (this.registerForm.invalid) return;
    this.store.dispatch(AuthActions.registerStart({
      username: this.registerForm.value.username,
      password: this.registerForm.value.password
    }));
  }
}
