<form *ngIf="showLoginForm" (ngSubmit)="onLogin()" [formGroup]="loginForm" class="p-fluid p-formgrid p-grid">
  <div class="p-field p-col-12">
    <label for="username">Username</label>
    <input id="username" type="text" pInputText name="username" formControlName="username" required>
  </div>
  <div class="p-field p-col-12">
    <label for="password">Password</label>
    <input id="password" type="password" pInputText name="password" formControlName="password" required>
  </div>
  <div class="p-field p-col-12">
    <button pButton type="submit" label="Login"></button>
  </div>
  <div class="p-field p-col-12">
    <button *ngIf="environment.firstTime" pButton type="button" label="Register" routerLink="/auth/register"></button>
  </div>
</form>

