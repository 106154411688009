<div class="inside-main-container">
  <div class="p-fluid center-div">

    <p-checkbox
      [binary]="true"
      inputId="binary"
      [(ngModel)]="addTimesheetMailNotification"
      (onChange)="addTimesheetMailNotificationChange($event)"/>
    Mail notification for add timesheet
  </div>
</div>
