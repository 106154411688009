import {createAction, props} from '@ngrx/store';
import {AddSubscriptionDto, SubscriptionDto, UpdateSubscriptionDto} from "../models/subscription.model";

export const loadSubscriptionStart = createAction(
  '[Subscription] Subscription Load  Start',
  props<{ filter: any }>()
);

export const loadSubscriptionSuccess = createAction(
  '[Subscription] Subscription Load Success',
  props<{ subscriptions: SubscriptionDto[] }>()
);

export const loadSubscriptionFailure = createAction(
  '[Subscription] Subscription Load Failure',
  props<{ error: string }>()
);

export const addSubscriptionStart = createAction(
  '[Subscription] Subscription Add Start',
  props<{ subscription: AddSubscriptionDto }>()
);

export const addSubscriptionSuccess = createAction(
  '[Subscription] Subscription Add Success',
  props<{ subscription: SubscriptionDto }>()
);

export const addSubscriptionFailure = createAction(
  '[Subscription] Subscription Add Failure',
  props<{ error: string }>()
);

export const updateSubscriptionStart = createAction(
  '[Subscription] Subscription Update Start',
  props<{ id: number, subscription: UpdateSubscriptionDto }>()
);

export const updateSubscriptionSuccess = createAction(
  '[Subscription] Subscription Update Success',
  props<{ subscription: SubscriptionDto }>()
);

export const updateSubscriptionFailure = createAction(
  '[Subscription] Subscription Update Failure',
  props<{ error: string }>()
);

export const deleteSubscriptionStart = createAction(
  '[Subscription] deleteSubscription Delete Start',
  props<{ id: number }>()
);

export const deleteSubscriptionSuccess = createAction(
  '[Subscription] deleteSubscription Delete Success',
  props<{ id: number }>()
);

export const deleteSubscriptionFailure = createAction(
  '[Subscription] deleteSubscription Delete Failure',
  props<{ error: string }>()
);
