import {createAction, props} from '@ngrx/store';
import {UserDto} from "../models/User.model";
import {updateMyInfoReq, uploadReq} from "../services/auth.service";

export const loginStart = createAction(
  '[Auth] Login Start',
  props<{ username: string; password: string }>()
);

export const loginSuccess = createAction(
  '[Auth] Login Success',
  props<{ ssid: string, userKey: string, token: string, user: UserDto }>()
);

export const loginFailure = createAction(
  '[Auth] Login Failure',
  props<{ error: string }>()
);

export const registerStart = createAction(
  '[Auth] Register Start',
  props<{ username: string; password: string }>()
);

export const registerSuccess = createAction(
  '[Auth] Register Success',
  props<{ ssid: string, userKey: string }>()
);

export const registerFailure = createAction(
  '[Auth] Register Failure',
  props<{ error: string }>()
);

export const autoAuthenticationStart = createAction(
  '[Auth] Authentication Start',
  props<{ ssid: string, userKey: string, token: string }>()
);

export const autoAuthenticationSuccess = createAction(
  '[Auth] Authentication Success',
  props<any>()
);

export const autoAuthenticationFailure = createAction(
  '[Auth] Authentication Failure',
  props<{ error: string }>()
);

export const updateMyInfoStart = createAction(
  '[Auth] updateMyInfo Start',
  props<{ value: updateMyInfoReq }>()
);

export const updateMyInfoSuccess = createAction(
  '[Auth] updateMyInfo Success',
  props<any>()
);

export const updateMyInfoFailure = createAction(
  '[Auth] updateMyInfo Failure',
  props<{ error: string }>()
);

export const uploadImageStart = createAction(
  '[Auth] uploadImage Start',
  props<uploadReq>()
);

export const uploadImageSuccess = createAction(
  '[Auth] uploadImage Success',
  props<any>()
);

export const uploadImageFailure = createAction(
  '[Auth] updateMyInfo Failure',
  props<{ error: string }>()
);

export const logout = createAction('[Auth] Logout');

export const loadAuthFromLocalStorage = createAction('[Auth] Load Auth From LocalStorage')
