import {createReducer, on} from '@ngrx/store';
import {SubscriptionDto} from "../models/subscription.model";
import * as SubscriptionActions from "../actions/subscription.action";

export interface SubscriptionState {
  subscriptions: SubscriptionDto[];
  error: string | null;
  loading: boolean;
}

const initialState: SubscriptionState = {
  subscriptions: [],
  error: null,
  loading: false
};

export const subscriptionReducer = createReducer(
  initialState,
  on(SubscriptionActions.loadSubscriptionSuccess, (state, {subscriptions}) => ({
    ...state,
    subscriptions: [...subscriptions]
  })),
  on(SubscriptionActions.loadSubscriptionFailure, (state, {error}) => ({...state, error})),
  on(SubscriptionActions.addSubscriptionSuccess, (state, {subscription}) => ({
    ...state,
    subscriptions: [...state.subscriptions, subscription]
  })),
  on(SubscriptionActions.addSubscriptionFailure, (state, {error}) => ({...state, error})),
  on(SubscriptionActions.updateSubscriptionSuccess, (state, {subscription}) => ({
    ...state,
    subscriptions: state.subscriptions.map(u => (u.id === subscription.id ? subscription : u))
  })),
  on(SubscriptionActions.updateSubscriptionFailure, (state, {error}) => ({...state, error})),
  on(SubscriptionActions.deleteSubscriptionSuccess, (state, {id}) => ({
    ...state,
    subscriptions: state.subscriptions.filter(subscription => subscription.id !== id)
  })),
  on(SubscriptionActions.deleteSubscriptionFailure, (state, {error}) => ({...state, error}))
);
