import {createFeatureSelector, createSelector} from '@ngrx/store';
import {SubscriptionState} from "../reducers/subscription.reducer";

export const selectSubscriptionState = createFeatureSelector<SubscriptionState>('subscription');

export const selectAllSubscription = createSelector(
  selectSubscriptionState,
  (state: SubscriptionState) => state.subscriptions
);

export const selectSubscriptionError = createSelector(
  selectSubscriptionState,
  (state: SubscriptionState) => state.error
);
