import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from "../../../../../environments/environment";
import {AddSubscriptionDto, SubscriptionDto, UpdateSubscriptionDto} from "../models/subscription.model";

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  private token: string = "";
  apiUrl = `${environment.myBackend}/user/subscription`;

  constructor(private http: HttpClient) {
  }

  addSubscription(value: AddSubscriptionDto): Observable<SubscriptionDto> {
    return this.http.put<SubscriptionDto>(this.apiUrl, value);
  }

  getSubscription(filters: any): Observable<SubscriptionDto[]> {
    let qs = ""
    if (filters && filters.sort) {
      qs += "sort=" + filters.sort[0] + "&sortOrder=" + filters.sort[1] + "&"
    }

    if (filters && filters.nameFilter) {
      qs += "nameFilter=" + filters.nameFilter + "&";
    }
    if (filters && filters.usernameFilter) {
      qs += "usernameFilter=" + filters.usernameFilter + "&";
    }

    if (filters && filters.allFilter) {
      qs += "allFilter=" + filters.allFilter + "&"
    }

    console.log(qs, filters)
    return this.http.get<SubscriptionDto[]>(`${this.apiUrl}?${qs}`);
  }

  updateSubscription(id: number, value: UpdateSubscriptionDto): Observable<SubscriptionDto> {
    return this.http.patch<SubscriptionDto>(`${this.apiUrl}/${id}`, value)
  }

  deleteSubscription(id: number): Observable<any> {
    return this.http.delete<SubscriptionDto>(`${this.apiUrl}/${id}`);
  }
}

