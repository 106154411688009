import {Component} from '@angular/core';
import {ButtonDirective} from "primeng/button";
import {FloatLabelModule} from "primeng/floatlabel";
import {InputTextModule} from "primeng/inputtext";
import {PaginatorModule} from "primeng/paginator";
import {ReactiveFormsModule} from "@angular/forms";
import {emptyUser} from "../../store/models/User.model";
import {InlineEditComponent} from "../../../../utils/inline-edit/inline-edit.component";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../store/AppState";
import {selectUserInformation} from "../../store/selectors/auth.selector";
import * as AuthActions from "../../store/actions/auth.action";
import {FileUploadEvent, FileUploadModule} from "primeng/fileupload";

@Component({
  selector: 'app-user-details',
  standalone: true,
  imports: [
    ButtonDirective,
    FloatLabelModule,
    InputTextModule,
    PaginatorModule,
    ReactiveFormsModule,
    InlineEditComponent,
    FileUploadModule
  ],
  templateUrl: './user-details.component.html',
  styleUrl: './user-details.component.css'
})
export class UserDetailsComponent {

  user = emptyUser;

  valueChange(event: any) {
    if (event.save) {
      let a = event;
      delete a["save"]
      this.store.dispatch(AuthActions.updateMyInfoStart({value: {...a}}));

    }
  }

  constructor(private router: Router, private store: Store<AppState>,) {
    this.store.select(selectUserInformation).subscribe(dataState => {
      this.user = dataState
    })
  }

  onUpload(event: FileUploadEvent) {
    console.log(event)
    // this.store.dispatch(AuthActions.uploadImageStart(event));
  }
}
