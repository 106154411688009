import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Router} from '@angular/router';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';
import * as SubscriptionActions from "../actions/subscription.action";
import {SubscriptionService} from "../services/subscription.service";

@Injectable()
export class SubscriptionEffects {

  loadSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubscriptionActions.loadSubscriptionStart),
      mergeMap(action =>
        this.subscriptionService.getSubscription(action).pipe(
          map(subscriptions => SubscriptionActions.loadSubscriptionSuccess({subscriptions})),
          catchError(error => of(SubscriptionActions.loadSubscriptionFailure({error})))
        )
      )
    )
  );

  addSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubscriptionActions.addSubscriptionStart),
      mergeMap(action =>
        this.subscriptionService.addSubscription(action.subscription).pipe(
          map(subscription => SubscriptionActions.addSubscriptionSuccess({subscription})),
          catchError(error => of(SubscriptionActions.addSubscriptionFailure({error})))
        )
      )
    )
  );

  updateSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubscriptionActions.updateSubscriptionStart),
      mergeMap(action =>
        this.subscriptionService.updateSubscription(action.id, action.subscription).pipe(
          map(subscription => SubscriptionActions.updateSubscriptionSuccess({subscription})),
          catchError(error => of(SubscriptionActions.updateSubscriptionFailure({error})))
        )
      )
    )
  );

  deleteSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubscriptionActions.deleteSubscriptionStart),
      mergeMap(action =>
        this.subscriptionService.deleteSubscription(action.id).pipe(
          map(() => SubscriptionActions.deleteSubscriptionSuccess({id: action.id})),
          catchError(error => of(SubscriptionActions.deleteSubscriptionFailure({error})))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private subscriptionService: SubscriptionService,
    private router: Router
  ) {
  }
}
