import {Component} from '@angular/core';
import {ButtonDirective} from "primeng/button";
import {FloatLabelModule} from "primeng/floatlabel";
import {InputTextModule} from "primeng/inputtext";
import {PaginatorModule} from "primeng/paginator";
import {ReactiveFormsModule} from "@angular/forms";
import {InlineEditComponent} from "../../../../utils/inline-edit/inline-edit.component";
import {Store} from "@ngrx/store";
import {FileUploadModule} from "primeng/fileupload";
import {CheckboxChangeEvent, CheckboxModule} from "primeng/checkbox";
import {SubscriptionDto} from "../../store/models/subscription.model";
import {selectAllSubscription} from "../../store/selectors/subscription.selector";
import * as SubscriptionActions from "../../store/actions/subscription.action";
import {selectUserInformation} from "../../store/selectors/auth.selector";
import {emptyUser, UserDto} from "../../store/models/User.model";

@Component({
  selector: 'app-subscription',
  standalone: true,
  imports: [
    ButtonDirective,
    FloatLabelModule,
    InputTextModule,
    PaginatorModule,
    ReactiveFormsModule,
    InlineEditComponent,
    FileUploadModule,
    CheckboxModule
  ],
  templateUrl: './subscription-details.component.html',
  styleUrl: './subscription-details.component.css'
})
export class SubscriptionComponent {
  addTimesheetMailNotification: boolean = false;
  subscriptions: SubscriptionDto[] = [];
  user: UserDto = emptyUser;

  addTimesheetMailNotificationChange(event: CheckboxChangeEvent) {
    let sub = this.subscriptions.filter(v => v.user.id == this.user.id && v.eventType == "ADD_TIMESHEET_EVENT")[0]
    console.log(this.addTimesheetMailNotification, sub)
    if (sub) {
      this.store.dispatch(SubscriptionActions.updateSubscriptionStart({
        id: sub.id,
        subscription: {
          user: this.user.id,
          eventType: "ADD_TIMESHEET_EVENT",
          status: this.addTimesheetMailNotification
        }
      }));
    } else {
      this.store.dispatch(SubscriptionActions.addSubscriptionStart({
        subscription: {
          user: this.user.id,
          eventType: "ADD_TIMESHEET_EVENT",
        }
      }));
    }

  }

  constructor(private store: Store,) {
    this.store.select(selectUserInformation).subscribe(dataState => {
      this.user = dataState
    })

    this.store.select(selectAllSubscription).subscribe(dataState => {
      console.log(dataState)
      this.subscriptions = dataState;
      let v = this.currentSubscriptionData("ADD_TIMESHEET_EVENT")
      if (v) {
        this.addTimesheetMailNotification = v.status

      }

    })
    this.store.dispatch(SubscriptionActions.loadSubscriptionStart({filter: {}}))
  }

  currentSubscriptionData(eventName: String): SubscriptionDto | undefined {
    return this.subscriptions.filter(v => v.user.id == this.user.id && v.eventType == eventName)[0]
  }

}
